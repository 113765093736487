<template>
  <div class="m-t-20 share">
    <p class="hidden-sm-and-up">{{$t("share.myid")}}：<span class="t-bule">{{ infos.nickname }}
        #{{ infos.userid }}</span></p>
    <div class="d-flex ai-center m-t-10">
      <h1>{{ $t("share.my") }}</h1>
    </div>
    <div class="m-t-10 d-flex flex-wrap ai-center">
      <div class="t-center link px-10 hover" @click="toinvite()">
        {{ origin + "login?invite=" + infos.invite_code }}
      </div>
      <div class="share_btn t-center fs-md mx-20 hover" v-clipboard:copy="origin + 'login?invite=' + infos.invite_code"
        v-clipboard:success="onCopy" v-clipboard:error="onError">
        {{ $t("share.link") }}
      </div>
      <div class="share_btn t-center fs-md hover" @click="qrFun">{{ $t("share.qrbtn") }}</div>
    </div>
    <!-- <p class="lh-3 m-t-10 fs-md">{{$t('share.sharetip')}}</p> -->
    <div class="d-flex ai-center jc-between flex-wrap">
      <div class="d-flex ai-center flex-wrap top">
        <h1 class="my-25">{{ $t("share.child") }}</h1>
        <p class="m-l-20">
          （{{ $t("share.tipsShort", { people1: zhiPeople, actived:activedPeo, people2: getPeople }) }}）
        </p>
      </div>
      <div class="d-flex ai-center bottom">
        <!-- <p>{{ $t("share.unget") }}：{{ ungetPeople }}</p>
        <div class="get_btn m-l-10 bg-bule px-15" :class="[{ disabled: !disabled }]" @click="onGet">
          {{ $t("share.tiptitle") }}<template v-if="!disabled">...</template>
        </div> -->
      </div>
    </div>
    <div class="main p-40" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)">
      <div class="title d-flex ai-center jc-between fs-xl m-b-20">
        <p class="item">{{ $t("share.userid") }}</p>
        <p class="item">{{ $t("dragon.level") }}</p>
        <p class="item">{{ $t("share.state") }}</p>
        <p class="item">{{ $t("share.activetime") }}</p>
        <p class="item">{{ $t("share.join") }}</p>
      </div>
      <div class="list d-flex ai-center jc-between m-b-15" v-for="(item, i) in childList" :key="i">
        <p class="item">#{{ item.userid }}</p>
        <p class="item">{{ levelName(item.lv) }} {{ levelStar(item.lv) }}</p>
        <!-- 激活状态0-未激活，1-已激活 -->
        <p class="item">{{ $t("share.state" + item.status) }}</p>
        <!-- 激活时间 -->
        <p class="item">
          {{_i18n.locale == "tw"?item.effective_time:_i18n.locale == "en"?item.effective_time_en:item.effective_time_in}}
        </p>
        <!-- 注册时间 -->
        <p class="item">
          {{_i18n.locale == "tw"?item.created_at:_i18n.locale == "en"?item.created_at_en:item.created_at_in}}
        </p>
      </div>
      <el-empty v-if="!loading && !childList.length" :description="$t('share.nodata')" class="m-t-50">
        <template #image>
          <img src="../assets/account/noshare.png" />
        </template>
      </el-empty>
    </div>
    <Pagination :meta="meta" @pageFun="pageFun"></Pagination>
    <el-dialog :visible.sync="dialogVisible" center :title="$t('share.qrtitle')" :modal="false">
      <div class="d-flex flex-column ai-center jc-center">
        <template v-if="infos.email">
          <VueQr v-if="qrStr" :text="qrStr" :margin="10" :size="140"></VueQr>
          <div class="d-flex ai-center m-t-20">
            <img src="../assets/account/warn.png" width="15px" height="15px" />
            <p class="t-color5 m-l-10">{{ $t("share.qrtips") }}</p>
          </div>
        </template>
        <p v-else class="t-center">{{ $t("share.qrtips") }}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pagination from "@/components/Pagination.vue";
export default {
  data () {
    return {
      loading: false,
      dialogVisible: false,
      origin: window.location.origin + "/#/",
      qrStr: "",
      meta: {
        current: 1,
        last: 1,
        total: 0,
      },
      account: "",
      childList: [],
      zhiPeople: 0, //直推人数
      activedPeo: 0, //已激活人数
      getPeople: 0, //已获得召唤券人数
      ungetPeople: 0, //未领取数
      disabled: true,
    };
  },
  components: {
    Pagination,
  },
  computed: {
    ...mapState({
      infos: (state) => state.infos,
    }),
  },
  mounted () {
    this.pageFun(1);
    let list = JSON.parse(this.$store.state.userServeList);
    list.map((ele) => {
      if (ele.server_id == this.$store.state.serverid) {
        if (ele.nickname) this.account = ele.title + "-" + ele.nickname;
        else this.account = ele.title;
      }
    });
  },
  methods: {
    levelName(lv) {
      switch (lv) {
        case 1: return this.$t('legion.levelName.1')
        case 2: return this.$t('legion.levelName.2')
        case 3: return this.$t('legion.levelName.3')
        case 4: return this.$t('legion.levelName.4')
      }
    },
    levelStar(lv) {
      switch (lv) {
        case 1: return '☆'
        case 2: return '★'
        case 3: return '★★'
        case 4: return '★★★'
      }
    },
    onCopy () {
      this.$message.success(this.$t("copy.ok"));
    },
    onError () {
      this.$message.error(this.$t("copy.no"));
    },
    qrFun () {
      var share_url = this.origin + "/login?invite=" + this.infos.invite_code;
      this.qrStr = share_url;
      this.dialogVisible = true;
    },
    dateFormat (timestamp, format) {
      if (String(timestamp).length === 10) {
        timestamp = timestamp * 1000
      }
      var date = new Date(timestamp)
      var Y = date.getFullYear()
      var M = date.getMonth() + 1
      var D = date.getDate()
      var hour = date.getHours()
      var min = date.getMinutes()
      var sec = date.getSeconds()
      if (format === 'MM-DD-YYYY HH:mm:ss') {
        return (M < 10 ? '0' + M : M) + '-' + (D < 10 ? '0' + D : D) + '-' + Y + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min) + ':' + (sec < 10 ? '0' + sec : sec)
      } else if (format === 'DD-MM-YYYY HH:mm:ss') {
        return (D < 10 ? '0' + D : D) + '-' + (M < 10 ? '0' + M : M) + '-' + Y + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min) + ':' + (sec < 10 ? '0' + sec : sec)
      } else {
        return '--'
      }
    },
    // var timestamp1 = 1626056435
    // console.log('date:', this.dateFormat(timestamp1, 'YYYY-MM-DD HH:mm:ss'))
    pageFun (page) {
      this.loading = true;
      this.$axios
        .get(
          this.$api.getinviteList,
          { page, pageSize: 10 },
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          this.zhiPeople = res.data.total; //邀请的人数 
          this.activedPeo = res.data.invite_activate_total //邀请且激活人数
          this.getPeople = res.data.summons; //获取的券数
          this.ungetPeople = res.data.remain_summons; //可领取券数
          // 英文和印尼的表达：月、日、年，或者日、月、年
          res.data.list.forEach(ele => {
            ele.effective_time_en = this.dateFormat(ele.effective_timestamp, "MM-DD-YYYY HH:mm:ss")
            ele.effective_time_in = this.dateFormat(ele.effective_timestamp, "DD-MM-YYYY HH:mm:ss")
            ele.created_at_en = this.dateFormat(ele.create_timestamp, "MM-DD-YYYY HH:mm:ss")
            ele.created_at_in = this.dateFormat(ele.create_timestamp, "DD-MM-YYYY HH:mm:ss")
          })
          this.childList = res.data.list;
          console.log(this.childList);
          this.meta = {
            current: res.data.page,
            last: res.data.totalPage || 1,
            total: res.data.total,
          };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    onGet () {
      if (this.ungetPeople <= 0) return this.$message(this.$t("share.tips2"));
      this.$msgbox
        .confirm(
          this.$t("share.tips3", { account: this.account, num: this.ungetPeople }),
          this.$t("share.tiptitle"),
          { confirmButtonText: this.$t("messageBox.confirm"), cancelButtonText: this.$t("messageBox.cancel") }
        )
        .then(() => {
          this.disabled = false;
          this.$axios
            .post(
              this.$api.assetshistory,
              { serverId: this.$store.state.serverid },
              { Serverid: this.$store.state.serverid }
            )
            .then((res) => {
              if (res.code == 200) {
                this.disabled = true;
                this.$message.success(this.$t('share.getsuc', { num: this.ungetPeople }));
                setTimeout(() => {
                  this.pageFun(1)
                }, 500);
              } else { }
            })
            .catch((err) => {
              this.disabled = true;
            });
        })
        .catch(() => { });
    },
    toinvite () {
      this.$store.commit("Set_serverid", "");
      this.$store.commit("Set_isLogin", false);
      this.$storage.clear();
      location.href = this.origin + "login?invite=" + this.infos.invite_code
    }
  },
};
</script>

<style lang="scss" scope>
.share {
  .link {
    line-height: 40px;
    border: 1px solid #4c5158;
    border-radius: 4px;
    background: #0f1016;
    &:hover {
      text-decoration: underline;
    }
  }
  .share_btn {
    width: 136px;
    line-height: 38px;
    border: 1px solid #37B497;
    border-radius: 4px;
    color: #37B497;
  }
  .main {
    min-height: 600px;
    background: rgba(33, 34, 47, 0.5);
    .item {
      width: 25%;
    }
  }
  .get_btn {
    border-radius: 4px;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}
@media screen and (max-width: 768px) {
  .share {
    .share_btn {
      margin-top: 10px;
    }
    .top {
      p {
        margin-left: 0;
      }
    }
    .bottom {
      margin: 10px 0;
    }
    .main {
      padding: 20px;
    }
  }
}
</style>